input[type='date']:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
input[type='date']:focus::-webkit-datetime-edit {
  color: black !important;
}

.date-picker-button:hover {
  background-color: transparent !important;
}

.clear-date-button:hover {
  background-color: transparent !important;
}

.not-in-focus-button {
  cursor: pointer;
}

.hidden-date-picker-placeholder input {
  color: transparent;
}

.date-picker {
  flex-direction: row-reverse;
}

.date-picker-header-text {
  margin-left: 28px;
  font-size: 12px;
}

.date-picker-container {
  width: 150px;
  .MuiInputAdornment-root {
    transform: translateY(-11px);
  }

  &.color-black {
    svg path {
      stroke: black;
    }
  }
}

.date-picker-button {
  transition: all 0.3s ease-in-out !important;
  transform: translate(20px, -4px);
  position: absolute !important;
  color: #b3b3b3 !important;
  font-size: 16px !important;
  z-index: 2;

  &.hidden {
    opacity: 0;
    z-index: 0;
  }
}

.color-gray {
  color: #b3b3b3;
}

.color-black {
  color: black;
}

.clear-date-button {
  position: absolute !important;
  z-index: 2;
  font-size: 20px !important;
  transform: translate(110px, -7px);
  color: black !important;
  font-weight: 200 !important;
}
