.add-admin-modal-content {
  width: 320px !important;
  margin: 10px auto !important;
  > div {
    align-self: center;
    padding-left: 0 !important;
  }

  .CustomButton__button {
    width: 320px;
  }

  .custom-input {
    width: 320px;
  }

  .person-number-input-container {
    padding-top: 0 !important;
    margin-top: -10px;
    margin-bottom: 15px;
  } 
}
