.header-text {
  margin-bottom: 5px;

  * {
    font-family: 'AudiType-Bold', sans-serif !important;
  }

  .arrow {
    font-family: 'AudiType-Normal', sans-serif !important;
  }
}

.table-body.danger {
  background: #f9dbdb 0% 0% no-repeat padding-box;
}

.custom-table {
  width: calc(100% + 36px) !important;
  margin-left: -18px;

  thead {
    padding-left: 18px;
    padding-right: 18px;
  }

  th {
    border-bottom: none;
  }

  tr {
    padding-left: 18px;
    padding-right: 18px;
  }

  td {
    padding-left: 18px;
    padding-right: 18px;
    border-bottom: none;
  }

  .dropdown-container {
    padding: 10px 0;
    position: absolute;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    z-index: 0;
    background-color: white;
    width: 260px;
    transform: translate(-38px, 5px);
    box-shadow: 0px 4px 6px #00000029;

    &.open {
      visibility: visible;
      opacity: 1;
      z-index: 1;
    }
  }

  .dropdown-item {
    font-family: 'AudiType-Normal', sans-serif !important;
    padding: 5px 10px;
    font-size: 14px;
    color: #333333;

    &:hover {
      cursor: pointer;
      background-color: rgba(#000000, 0.1);
    }
  }
}

thead:after {
  content: '';
  position: absolute;
  left: 21px;
  height: 1px;
  width: calc(100% - 43px);
  border-bottom: 2px solid #666666;
}

tbody tr:not(:first-child):after {
  content: '';
  position: absolute;
  left: 21px;
  transform: translateY(-1px);
  width: calc(100% - 43px);
  border-bottom: 2px solid #cecece4d;
}

tbody.add-padding:before {
  content: '@';
  display: block;
  line-height: 15px;
  text-indent: -99999px;
}

.cursor-pointer {
  cursor: pointer;
}

.arrow-container {
  transform: rotate(270deg);
  margin-left: 5px;
  color: #707070;
  display: inline-block;
}
