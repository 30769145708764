@import '../../assets/styles/fonts.scss';
@import '../../assets/styles/variables.scss';

.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 300px;
  max-width: 300px;
  min-height: 800px;
  max-height: 1080px;
  margin-right: 15px;
  @media only screen and (max-width: 1900px) {
    margin-left: 8vw;
  }
  @media only screen and (max-width: 1000px) {
    margin: 0;
  }
  &__logoWrapper {
    position: absolute;
    top: 2vh;
    cursor: pointer;
  }
  &__headerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media only screen and (max-width: 1000px) {
      height: 200px;
    }
  }
  &__mainHeader {
    display: inline;
    margin: 0;
  }
  &__subHeader {
    margin: 0;
  }
  &__checkboxWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    max-width: 300px;
  }
  &__textWrapper {
    display: inline;
    flex-direction: row;
    margin-left: 15px;
  }
  &__checkboxText {
    font-size: 12px;
    margin-right: 0.3rem;
  }
  &__checkboxTextError {
    font-size: 12px;
    margin-right: 0.3rem;
    color: $errorBorder;
  }
  &__checkboxLinkText {
    font-size: 12px;
    margin-right: 0.3rem;
    text-decoration: underline;
    cursor: pointer;
  }
  &__checkboxLinkTextError {
    font-size: 12px;
    margin-right: 0.3rem;
    text-decoration: underline;
    color: $errorBorder;
    cursor: pointer;
  }
  &__InfoIconWrapper {
    padding: 2px;
    padding-left: 4px;
    padding-right: 4px;
    cursor: pointer;
  }
  &__formWrapper {
    background-color: #ffffff;
  }
}
