.custom-switch-label {
  span {
    font-family: 'AudiType-Bold', sans-serif !important;
  }
}

.custom-switch {
  width: 48px !important;
  height: 24px !important;
  padding: 0 !important;
  .MuiSwitch-switchBase {
    padding: 0;
    margin: 6px;
    transition: all 0.3s ease-in-out;
    &.Mui-checked {
      transform: translateX(24px);
      color: #fff;
      & + .MuiSwitch-track {
        background-color: #f50537;
        opacity: 1;
      }
      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }
    }
    &.Mui-focusVisible .MuiSwitch-thumb {
      color: #33cf4d;
      border: 6px solid #fff;
    }
    &.Mui-disabled .MuiSwitch-thumb {
      color: white;
      opacity: 0.8;
    }
    &.Mui-disabled + .MuiSwitch-track {
      opacity: 0.7;
    }
  }
  .MuiSwitch-thumb {
    box-sizing: border-box;
    background-color: white;
    opacity: 0.8;
    width: 12px;
    height: 12px;
  }
  .MuiSwitch-track {
    border-radius: 12px;
    height: 22px;
    border: 1px solid #00000080;
    background-color: #4dd865;
    opacity: 1;
    transition: 0.5s all ease-in-out;
  }
}
