input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.custom-text-field {
  width: 280px;
  label[data-shrink='true'] {
    transform: translate(0, 5px) scale(0.75);
  }
}
.field-wrapper {
  margin-bottom: 16px;
}
