.page-content-container {
  position: relative;
}

.page-enter-done {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  overflow: hidden;
  opacity: 1;
  transition: opacity 300ms;
}

.page-exit-active {
  height: 0;
  width: 0;
  top: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
}

.content.page-content-container {
  margin-top: 68px;
}
