.export-button {
  background-color: transparent;
  border: 1px solid black;
  height: 57px;
  width: 164px;
  font-size: 16px;
  cursor: pointer;

  svg {
    transform: translateY(2px);
  }
}
