.CustomModal {
  &__PDFWrapper {
    max-height: 720px;
    min-width: 630px;
    margin-top: 20px;
    overflow-y: scroll;
    cursor: pointer;
    @media only screen and (max-width: 1000px) {
      max-width: 90vw;
      max-height: 80vh;
    }
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    ::-webkit-scrollbar-track {
      background-color: darkgrey;
    }
    ::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
    }
  }
  &__PDF {
    display: flex;
    flex-grow: 1;
    max-width: 625px;
    margin: 0;
    margin-top: 25px;
    flex-wrap: wrap;
    @media only screen and (max-width: 1000px) {
      max-height: 95%;
    }
  }
  &__HTMLWrapper {
    overflow-y: scroll;
    display: flex;
    margin-top: 25px;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: aqua;
    }
    &::-webkit-scrollbar-track {
      background-color: darkgrey;
    }
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
    }
    @media only screen and (max-width: 1000px) {
      max-width: 90vw;
      max-height: 80vh;
    }
  }
}
