.results-container {
  background-color: #e5e5e5;
  height: 46px;
  font-size: 12px;
  margin: 0 -18px;
  padding: 0 18px;

  .main-grid {
    height: 100%;
  }

  .results-per-page-button {
    font-size: 14px;
    cursor: pointer;
  }

  .arrow-container {
    transform: rotate(270deg);
    margin-left: 5px;
    color: #707070;
  }

  .dropdown-container {
    position: absolute;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    z-index: 0;
    background-color: white;
    width: 160px;
    transform: translate(-70px, 75px);
    box-shadow: 0px 4px 6px #00000029;

    @media screen and (min-width: 1250px) {
      transform: translate(-50px, 75px);
    }

    &.open {
      visibility: visible;
      opacity: 1;
      z-index: 1;
    }
  }

  .dropdown-item {
    margin: 10px 0;
    padding-left: 10px;
    font-size: 14px;
    color: #333333;
  }
}
