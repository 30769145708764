.delete-admin-modal-content {
  width: 320px !important;
  margin: 10px auto !important;

  > div {
    align-self: center;
    padding-left: 0 !important;
  }

  .CustomButton__button {
    width: 160px;
  }

  .dekete-text-container {
    padding-top: 0 !important;
    font-size: 16px;
    margin-top: -10px;
    margin-bottom: 15px;
    color: #333333;
  }

  .confirm-buttons {
    > div {
      display: inline-block;
    }

    .button-transparent-background {
      button {
        background-color: transparent;
        border: none;
        color: #666666;
      }
    }
  }
}
