.pagination-container {
  color: #333333;
}

.page-select-button {
  padding: 0;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border: 1px solid #e5e5e5;
  color: #b3b3b3;
  height: 24px;
  width: 24px;
  background-color: transparent;
  margin: 0 4px;
  cursor: pointer;

  &.selected {
    border: 1px solid #333333;
    color: #333333;
    font-family: 'AudiType-Bold', sans-serif;
  }

  &.not-clickable {
    cursor: default;
    width: 22px;
    height: 22px;
  }
}

.page-button-text {
  margin: 0 auto;
}

.next-prev-button {
  height: 24px;
  transition: all 0.3s ease-in-out;
  border: 0;
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;

  > div {
    display: inline-block;
  }
}

.prev-arrow {
  margin-right: 3px;
}

.next-arrow {
  margin-left: 3px;
}

.next-prev-arrow {
  font-family: 'AudiType-Normal', sans-serif;
  font-size: 16px;
}
