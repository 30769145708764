.custom-card.card-container {
  cursor: pointer;
  margin: 30px auto;
  height: 154px;
  width: 320px;
  background-color: #f2f2f299;
  display: table;
  transition: all 0.3s ease-out;

  &:hover {
    box-shadow: 0px 3px 6px #00000029;
  }

  .card-content {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .icon-container {
    svg {
      height: 40px;
      width: 31px;
      margin-bottom: 15px;
      g * {
        stroke: black;
      }
    }
  }

  .hidden-input-container {
    display: none;
  }

  .text-container {
    color: #1a1a1a;
  }
}
