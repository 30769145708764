.search-container {
  width: 324px;

  .custom-input {
    display: inline-block;
    width: 100%;

    > div {
      width: 100%;
    }
  }

  .search-icon {
    position: absolute;
    display: inline-block;
    margin-top: 12px;
    margin-left: -20px;
  }

  label.Mui-focused {
    transform: translate(0, 1.5px) scale(0.75) !important;
  }

  &.empty {
    label {
      transform: translate(0, 9px) scale(1);
    }
  }
}
