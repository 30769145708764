.table-footer-container {
  height: 57px;
  margin-top: 15px;
  margin-bottom: 30px;
  display: flex;
  .main-grid {
    height: 57px;
    height: 100%;
    font-size: 12px;
  }

  > div {
    display: flex;
    align-items: center;
  }

  .item-left {
    justify-content: flex-start;
    flex: 1;
  }

  .item-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: auto;
  }

  .item-right {
    justify-content: flex-end;
    flex: 1;
  }
}
